$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; $svg_icons: unquote("moon|sun");$add_color_scheme_toggle_to_header: unquote("true");// Theme: Dark-Light Toggle
// Target: common scss
// Last Edited: 2023-08-07 16:31:35 UTC
.header-toggle-button {
  // this is not ideal, we usually don't want to mess
  // with button padding...
  // but the problem here is that the other header elements aren't buttons
  // they are anchors...
  .btn {
    padding: 0;
  }
}
